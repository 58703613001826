import axios from 'axios';
import {
  // IGame,
  IGameDetails,
  IGameNftContract,
  IGameWithContracts,
  IScholarship,
  IUpdateAccount,
  IVaultNftResponse,
  IGetScholarAssets,
  IScholarGame,
  IScholarNftResponse,
  IPayoutHistory,
  IBenjiPerformance,
} from '../types/interfaces';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers!['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    if (
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      window.location.pathname !== '/login'
    ) {
      localStorage.clear();
      window.location.pathname = '/login';
    }
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      window.location.pathname !== '/login'
    ) {
      localStorage.clear();
      window.location.pathname = '/login';
    }
    return Promise.reject(error);
  }
);

interface Ilogin {
  email: string;
  password: string;
}

interface IRecoverPassword {
  code: string;
  password: string;
  confirm_password: string;
}

// registration apis

export const signup = (data: any) => api.post('/v1/auth/signup', data);

export const emailVerification = (code: string, token: string) =>
  api.post(`/v1/auth/email-verification/${token}`, { code });

export const getNewVerificationCode = (email: string) =>
  api.post(`/v1/auth/email-verification-token`, { email });

export const login = (data: Ilogin) => api.post('/v1/auth/login', data);

export const forgotPassword = (email: string) =>
  api.post('/v1/auth/forgot-password', { email });

export const recoverPassword = (data: IRecoverPassword, token: string) =>
  api.post(`/v1/auth/recover-password/${token}`, data);

export const updateUser = (data: any) => api.patch('/v1/user/update', data);

export const userImage = (data: any) => api.patch('/v1/user/image', data);

export const updatePayoutWallet = (data: IUpdateAccount) =>
  api.patch('/v1/wallet/update', data);

// get userinfo

export const getUserInfo = () => api.get('/v1/user');

//  games api

export const getGames = (filter?: string) =>
  api.get(`/v1/games${filter ? `?filter=${filter}` : ''}`);

export const getGameById = (id: string) =>
  api.get<IGameDetails>(`/v1/game/${id}`);

export const getGamesWithContracts = () =>
  api.get<IGameWithContracts[]>('/v1/games/contracts');

export const getGameNftContracts = (gameId: string) =>
  api.get<IGameNftContract[]>(`/v1/game/${gameId}/contracts`);

export const scholarGames = (id: string) =>
  api.get<IScholarGame[]>(`/v1/scholar/${id}/game`);

export const getBalanceInGame = (gameId: string, publicAddress: string) =>
  api.get(`/v1/scholar/game/${gameId}/public-address/${publicAddress}/balance`);

// get badges

export const getBadges = () => api.get('/v1/badges');

export const mintedBadges = (id: string) => api.get(`/v1/scholar/${id}/badges`);

// connect wallet

export const connectWallet = (public_address: string) =>
  api.post('/v1/wallet/generate', { public_address });

export const getWalletAccounts = () => api.get('/v1/wallet/accounts');

// minting api

// export const mint = (data: {
//   badge_id: string;
//   badge_type: number;
//   public_address: string;
// }) => api.post('/v1/minting', data);

export const mint = (data: {
  badge_id: string;
  badge_type: number;
  public_address: string;
}) => api.post('v1/user/badge/minting', data);

// nft apis

export const getScholarAssets = (data: IGetScholarAssets) => {
  return api.get<IScholarNftResponse[]>(
    `/v1/assets/${data.public_address}/${data.user_id}/nfts`
  );
};

export const getUserNftsByGame = (game_id: string, public_address: string) =>
  api.get(`/v1/assets/${public_address}/${game_id}`);

export const getNftMetadata = (data: {
  contract_address: string;
  token_id: string;
  token_uri?: string;
}) => api.post('/v1/nft/metadata', data);

export const getAllNfts = (data: { contract_id: string }, cursor?: string) =>
  api.post<IVaultNftResponse>(
    `/v1/assets/list${cursor && '?cursor=' + cursor}`,
    data
  );

// scholarships api

export const getScholarships = () =>
  api.get<IScholarship[]>('/v1/game-scholarships');

export const applyScholarship = (data: {
  title: string;
  about: string;
  game_id: string;
  badge_id: string;
}) => api.post('/v1/user/scholarship', data);

// user minted badges

export const getUserMintedBadges = (user_id: string) =>
  api.get(`/v1/user/${user_id}/badges`);

// notifications

export const getNotifications = (user_id: string) =>
  api.get(`/v1/notification?id=${user_id}`);

export const getUserPayoutHistory = (user_id: string) =>
  api.get<IPayoutHistory[]>(`/v1/payout/${user_id}`);

export const benjiPerformance = (public_address: string) =>
  api.get<IBenjiPerformance>(`v1/benji/perfomance/${public_address}`);
